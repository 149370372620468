/* Universal box-sizing rule */
* {
  box-sizing: border-box;
}

.parts-list-button-group {
  display: flex;
  justify-content: space-between; /* Align the button group content to the right */
  margin-top: 20px; /* Space between content and buttons */
}

.container .button-group {
  display: flex;
  justify-content: flex-start; /* Align buttons to the left */
  gap: 10px;
}

#closeMenuBtn {
  margin-left: auto; /* Push the close button to the far right */
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#closeMenuBtn:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
  margin-left: 5px;
}

.success-message {
  color: green;
  font-size: 1em;
  margin-bottom: 10px;
}

/* Centering and alignment for popup content */
#partsListSection h3 {
  text-align: center;
}

#partsListSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  min-width: 650px;
  max-width: 850px;
}

.container label {
  font-weight: bold;
}

.container input,
.container select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* partsList.css */

.action-buttons {
  text-align: right;
}

.action-buttons .edit-btn,
.action-buttons .copy-btn,
.action-buttons .delete-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.action-buttons .edit-btn i,
.action-buttons .copy-btn,
.action-buttons .delete-btn i {
  color: #007bff;
}

.action-buttons .edit-btn i:hover,
.action-buttons .copy-btn i:hover,
.action-buttons .delete-btn i:hover {
  color: #0056b3;
}

/* Disabled button styles */
.action-buttons button:disabled {
  cursor: not-allowed; /* Change the cursor to not-allowed */
  opacity: 0.6; /* Reduce opacity to indicate disabled state */
}

.action-buttons button:disabled i {
  color: #ccc; /* Change the icon color to a lighter shade */
}

/* Specific styles for disabled copy button */
.action-buttons .copy-btn:disabled i {
  color: #bbb; /* Even lighter color for disabled copy icon */
}

/* Centering the error/success messages */
#partsListSection .error-message,
#partsListSection .success-message {
  text-align: center;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

/* Wrapper to control scrolling */
.table-wrapper {
  max-height: 700px; /* Set a max height for the table wrapper */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%; /* Ensure the wrapper takes full width */
}

.bottom-container {
  margin-top: 20px;
}

.custom-datagrid .MuiDataGrid-cell {
  font-size: 14px;
}

#parts-list-page .MuiDataGrid-root {
  border: none !important;
}
