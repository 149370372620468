/* Ensure the header is at the top of the screen */
.header {
  position: fixed; /* Fixes the header at the top */
  top: 0; /* Aligns it to the very top of the page */
  left: 0; /* Aligns it to the left of the page */
  width: 100%; /* Makes the header span the full width of the page */
  z-index: 1003; /* Ensures the header stays on top of other content */
  background-color: var(
    --header-background-color
  ); /* Keeps the background color */
  padding: 10px 20px;
  min-height: 100px; /* Ensures the header has a minimum height */
  display: flex;
  align-items: center; /* Centers the header content vertically */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.041); 
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensures the content spans the full width */
}

.header-content h1 {
  color: var(--header-primary-text-color);
  margin: 0;
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  flex-grow: 1; /* This ensures the title grows to fill the space */
}

/* Subtitle styling */
.header-content h2 {
  color: var(--header-secondary-text-color);
  margin: 0;
  margin-right: 20px;
  font-size: 1em;
  font-weight: 600;
  text-align: right;
}

.header-content .menu-button {
  margin-right: 10px;
}

.help-button {
  margin-left: auto; /* This ensures the help button is pushed to the right */
}

img {
  vertical-align: middle;
}

.right-side{
  margin-left: 168px;
}

/* Avatar Container */
.avatar-container {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effect */
.avatar-container:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow effect */
}

/* Active/Click Effect */
.avatar-container:active {
  transform: scale(0.95); /* Slightly decrease size on click for feedback */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adjust shadow to show a click effect */
}

.header-left-section {
  display:flex;
  align-items: center;
}