/* Input container for SKU and Description */
.item-select-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Adds space between SKU and Description lines */
  background-color: var(--item-select-background-color);
}

.item-top-row {
  display: flex;
  justify-content: space-between;
}

.item-top-row div {
  display: flex;
  flex-direction: column;
  align-items: left;
}

/* Container for the input fields */
.input-group-container {
  display: flex;
  justify-content: space-between;
  /* or flex-end */
  align-items: center;
  margin: 20px 0;
}

/* Common styling for the input fields */
.input-group-container input,
.input-group-container select {
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 2px solid #ccc;
  width: 100%;
  max-width: 150px;
  text-align: center;
}

.item-select {
  display: flex;
  align-items: left;
}

/* SKU dropdown styling */
#sku {
  width: 200px;
  /* Adjust as needed */
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  height: 40px !important;
}

.select2-container .select2-selection--single {
  height: 40px !important;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  padding: 5px;
  font-size: 1em;
  align-items: center !important;
  width: 100%;
  max-width: 200px;
  text-align: center;
}

.select2-selection__placeholder{
  font-size: 1em !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder{
  color: #333 !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  font-size: 1em;
  padding: 0px;
  text-align: center;
}

.select2-selection__clear {
  display: none;
}

/* Add/Edit button */
#editButton {
  /* background-color: #28a745; */
  color: white;
  width: 200px;
}

/* Specific styling for select boxes */
/* .input-group-container select {
  appearance: none;
  background-color: #f0f0f0;
} */

.above-readout-container {
  display: flex;
  justify-content: space-between;
  /* or flex-end */
}

/* {
  background-color: white;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 2px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 200px;
  min-width: 150px;
  text-align: center;
} */

.item-select-description {
  background-color: white;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid var(--readout-border-color);
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  width: 100%;
  max-width: 250px;
  min-width: 250px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* text-wrap: wrap;
  text-overflow: ellipsis; */
}

.item-select-piece-weight {
  background-color: white;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid var(--readout-border-color);
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* text-wrap: wrap;
  text-overflow: ellipsis; */
}