/* Ensure the button-group container aligns content to the right */
.success-button-group {
  display: flex;
  justify-content: flex-end; /* Align the button group content to the right */
  margin-top: 20px; /* Space between content and buttons */
}

/* Style for the close button */
#backBtn {
  margin-left: auto; /* Push the close button to the far right */
}

#backBtn:hover {
  background-color: #0056b3;
}

/* General button styling */


button:hover {
  background-color: #0056b3;
}

/* Label styling */
.container label {
  font-weight: bold;
}

/* Input and select styling */
.container input,
.container select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Container-specific adjustments */
.container {
  padding-right: 20px; /* Ensure proper padding */
  padding-left: 20px; /* Ensure proper padding */
}

/* New CSS for #helpSection to center contents */
#successSection {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center contents horizontally */
  max-width:650px;
  padding-top: 50px;
}