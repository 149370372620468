/* Toggle Read Button */
#toggleReadButton.toggle-read-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px; /* Space between icon and text */
    font-size: 16px;
    background-color: #28a745 !important; /* Green background */
    color: white !important;
    transition: background-color 0.3s, color 0.3s;
  }
  
  #toggleReadButton.toggle-read-button.stop-state {
    background-color: #dc3545 !important; /* Red background */
  }
  
  #toggleReadButton.toggle-read-button i {
    font-size: 18px;
  }
  