/* Wrapper to control scrolling */
.table-wrapper {
  max-height: 700px; /* Set a max height for the table wrapper */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%; /* Ensure the wrapper takes full width */
}

/* table {
  width: 100%; 
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px; 
  text-align: left;
  border-bottom: 1px solid #ddd;
  vertical-align: middle; 
}

th {
  font-weight: bold;
}

tbody tr:hover {
  background-color: #f9f9f9;
} */

.bottom-container {
  margin-top: 20px;
}

/* .td-text {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.group-users-section .remove-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 18px;
}

.group-users-section .remove-btn:hover {
  color: darkred;
}

.group-users-section .MuiDataGrid-root {
  border: none !important; 
}