.group-scales-section {
  margin: 0px;
}

.add-scale-section {
  margin-bottom: 15px;
}

/* .add-scale-section input {
  padding: 5px;
  margin-right: 10px;
} */

/* .add-scale-section button {
  padding: 5px 10px;
} */

/* .group-scales-section table {
  width: 100%;
  border-collapse: collapse;
}

.group-scales-section table th, 
.group-scales-section table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
} */

.group-scales-section .remove-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 18px;
}

.group-scales-section .remove-btn:hover {
  color: darkred;
}

.group-scales-section .MuiDataGrid-root {
  border: none !important; 
}

/* .add-scale-section button {
  margin-bottom: 10px;
} */