.subgroup-page {
  margin-top: 70px;
}

/* Container styling */
.subgroup-container {
  position: relative; /* Ensure that the container is the reference for absolutely positioned elements */
  width: 100%;
  max-width: 1200px;
  min-width: 650px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.subgroup-page-section {
  padding: 20px;
}

.subgroup-page-header-container {
  text-align: center;
}

/* Wrapper to control scrolling */
.table-wrapper {
  max-height: 700px; /* Set a max height for the table wrapper */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%; /* Ensure the wrapper takes full width */
}

/* .bottom-container {
  margin-top: 20px;
} */

.subgroup-page .remove-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 18px;
}

.subgroup-page .remove-btn:hover {
  color: darkred;
}

.subgroup-page .MuiDataGrid-root {
  border: none !important;
}

.subgroup-bottom-buttons {
  display: flex;
  justify-content: flex-end; /* Align the button group content to the right */
  margin-top: 20px; /* Space between content and buttons */
}
