/* Readout container for item count */
.count-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
  background-color: var(--item-count-background-color);
  margin: 10px 0;
  border-radius: 10px;
}

/* Item count text styling */
#itemCount {
  font-size: 4em; /* Large font size */
  color: white; /* Dark grey color */
  font-weight: bold;
  text-align: center;
}

/* Container for weights under the item count */
.weights-container {
  display: flex;
  justify-content: space-around;
  margin-top: 10px; /* Space between item count and weights */
}

#totalWeight {
  font-size: 1em; /* Adjust as needed */
  color: #333;
}

.pwoLabelDiv {
  display: flex;
  flex-direction: row !important;
  align-items: center; /* Align items vertically centered */
  justify-content: flex-start; /* Justify items to the left */
  gap: 10px; /* Optional: Add space between elements */
}

.pwoLabelDiv label {
  display: flex; /* Ensure labels are flex items */
  align-items: center; /* Center items vertically within labels */
}

/* Gray out and disable the input when the toggle is off */
#pieceWeightOverride[disabled] {
  background-color: #e0e0e0; /* Grayed out color */
  cursor: not-allowed;
}

/* Center the text inside the input field */
#pieceWeightOverride {
  text-align: center; /* Ensure the text inside the input field is centered */
  /* padding: 5px;
  border-radius: 4px; */
  border: 1px solid #ccc;
  font-size: 1em;
}

/* Toggle Switch Style */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #28a745; /* Adjust color as needed */
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Align the labels */
.input-group-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
}

/* Container for the input fields */
.input-group-container {
  display: flex;
  justify-content: space-between; /* or flex-end */
  align-items: center;
  margin: 20px 0;
}

/* Specific styling for select boxes */
.input-group-container select {
  appearance: none;
  background-color: #f0f0f0;
}

.above-readout-container {
  display: flex;
  justify-content: space-between; /* or flex-end */
}

.sensor-val-container {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

.bottom-readout-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 1em;
}

.bottom-readout-container div {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left along the cross axis */
  justify-content: flex-start; /* Justify items to the left along the main axis */
}

/* Specific styling for the readout display */
.bottom-readout {
  background-color: white;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid var(--readout-border-color);
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  width: 100%;
  max-width: 200px;
  min-width: 100px;
  text-align: center;
}

#totalWeightContainer,
#unitsContainer,
#averagePointsContainer,
#pieceWeightOverrideContainer {
  width: 170px;
}