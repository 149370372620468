/* Wrapper to control scrolling */
.table-wrapper {
  max-height: 700px; /* Set a max height for the table wrapper */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%; /* Ensure the wrapper takes full width */
}

/* Adjust the table within the wrapper */
table {
  width: 100%; /* Make table take up full width of the wrapper */
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px; /* Adjust padding to control row height */
  text-align: left;
  border-bottom: 1px solid #ddd;
  vertical-align: middle; /* Vertically center content in cells */
}

th {
  font-weight: bold;
}

tbody tr:hover {
  background-color: #f9f9f9;
}

.bottom-container {
  margin-top: 20px;
}

.td-text {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
