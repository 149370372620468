.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--modal-overlay-color); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's above everything */
  }
  