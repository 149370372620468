/* src/styles/SideMenu.css */
.menu-button {
  top: 15px;
  left: 15px;
  background-color: var(--header-primary-text-color); /* Ensure the button is visible */
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001; /* Ensure the button is above the menu */
  padding: 10px; /* Add padding to make the button more prominent */
  border-radius: 4px; /* Optional: Round the edges */
}

/* Style for the transparent overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust transparency here */
  z-index: 1001; /* Ensure it's just below the menu */
}

/* Ensure the side menu is on top of the overlay */
.side-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 200px; /* Adjust width as needed */
  height: 100%;
  background-color: white;
  color: white;
  z-index: 1002; /* Ensure it's above the overlay */
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.side-menu.collapsed {
  transform: translateX(-100%);
}

.side-menu:not(.collapsed) {
  transform: translateX(0);
}

.menu-content {
  margin-top: 110px;
  margin-left: 5px;
}

.menu-content ul li {
    margin: 20px 0; /* Add margin to space out menu items */
  }

.menu-content ul {
  list-style-type: none;
  padding: 0;
}

.menu-content li {
  padding: 15px 20px;
}

.menu-content li a {
  color: black;
  text-decoration: none;
  display: block;
  font-weight: bold;
}

.menu-content ul li a:hover {
    background-color: #cacaca; /* Highlight on hover */
  }