#account-page {
  margin-top: 70px;
}

/* Container styling */
.account-container {
  position: relative; /* Ensure that the container is the reference for absolutely positioned elements */
  width: 100%;
  max-width: 1200px;
  min-width: 650px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.account-page-section {
  padding: 20px;
  
}

.account-page-header-container {
  text-align: center;
}

.account-page-text-input{
  margin-bottom: 10px;
}