/* Help Button Styles */
.help-button {
    background-color: #f5f5f5; /* Light grey background */
    border: 2px solid #007bff; /* Blue border */
    color: #007bff; /* Blue icon color */
    border-radius: 50%; /* Circular shape */
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    outline: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 120px; /* Adjust this value to position below the header */
    right: 25px; /* Align to the right edge of the container */
    z-index: 999; /* Ensure the button is on top of other content */
  }
  
  /* Ensure icon is centered and visible */
  .help-button i {
    color: #007bff; /* Ensure the icon color is blue */
    font-size: 20px; /* Increase font size slightly for better visibility */
  }
  
  /* Hover and Focus Effects */
  .help-button:hover,
  .help-button:focus {
    background-color: #007bff; /* Blue background */
    color: #fff; /* White icon color */
  }
  
  .help-button:hover i,
  .help-button:focus i {
    color: #fff; /* Ensure icon turns white on hover/focus */
  }
  
  /* Tooltip */
  .help-button[title]:hover::after {
    content: attr(title);
    position: absolute;
    top: -35px; /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 999;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Tooltip Arrow */
  .help-button[title]:hover::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
    z-index: 999;
  }
  
  