/* LoadingSpinner.css */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 1000; /* High z-index to make sure it's on top of everything */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner-overlay .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black; /* Optional: Change text color */
  }
  
  .spinner-message {
    margin-top: 10px;
    font-size: 16px;
    color: black; /* Change this if you want a different text color */
  }
  