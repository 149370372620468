:root {
    /* --primary-color: #007bff;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --light-color: #f8f9fa;
    --dark-color: #343a40; */
  
    --body-background-color: #f0f0f0;

    --background-color: #ffffff;
    --text-color: #212529;

    /* color values to change */
    --header-background-color: #eff6fb;
    /* --header-background-color: red; */
    --header-primary-text-color: #12527d;
    --header-secondary-text-color: #000000;
    
    --button-primary-color: #60B9E5;
    --button-active-color: #2196f3;

    --device-connect-background-color: #EAEAEA;

    --item-select-background-color: #EFF6FB;

    --item-count-background-color: #12527D;

    --readout-border-color: #ABAAAA;

    --button-disabled-color: #e0e0e0;

    --settings-terminal-bg-color: #333;

    --no-access-page-text-color: #ff0000d0;

    --login-input-icons-color: #9e9e9e;
    --login-link-color: #ffa500;
    --login-forgot-text-color: #0860F8;
    --login-splash-bg-color: #12527d;

    /* --modal-confirm-color: ; */
    --modal-overlay-color:  rgba(0, 0, 0, 0.103);
  }