#no-access-page {
    margin-top: 80px;
    text-align: center;
    color: var(--no-access-page-text-color);
  }
  
  /* Container styling */
  .no-access-container {
    position: relative; /* Ensure that the container is the reference for absolutely positioned elements */
    width: 100%;
    max-width: 1200px;
    min-width: 650px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .no-access-page-section {
    padding: 10px;
    
  }
  
