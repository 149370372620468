html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#login-page {
  display: flex;
  height: 100vh;
  width: 100vw; /* Make sure it takes up the full width of the viewport */
  justify-content: space-between;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
}

.login-page-image {
  flex: 1;
  background: url("../assets/ncounter-splash.png") no-repeat center center;
  background-size: contain; /* Ensure the image is fully visible and maintains its aspect ratio */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0; /* Prevent the image container from forcing a minimum width */
  background-color: var(--login-splash-bg-color);
  height: 100%; /* Make sure the image container takes up the full height */
}

@media (max-aspect-ratio: 1/1) {
  /* When the window is taller than it is wide */
  .login-page-image {
    background-size: contain; /* Ensure the image scales down while keeping the aspect ratio */
    width: 100%; /* Make sure the image takes up the full width of the window */
  }
}


.login-form-container {
  flex: 0.4;
  padding: 2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 0; /* Prevent the form container from forcing a minimum width */
}

.login-form-header {
  text-align: center;
}

.login-logo {
  max-width: 150px;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-group input {
  width: 100%;
  padding: 0.8rem;
  padding-left: 2.5rem; /* Add left padding to make space for the icon */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group .icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: var(--login-input-icons-color);
  font-size: 1.2rem; /* Adjust size if necessary */
}

.login-button {
  background-color: #28a745;
  color: white;
  padding: 0.8rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.login-button:hover {
  background-color: #218838;
}

.forgot-password {
  display: block;
  text-align: right;
  color: var(--login-forgot-text-color);
}

.separator {
  text-align: center;
  margin: 2rem 0;
  font-size: 0.9rem;
  color: #888;
}

.error-message {
  color: red;
  text-align: center;
}

.login-links {
  color: var(--login-link-color);
  cursor: pointer; /* Change the cursor to pointer */
}

.url-link-no-underline {
  text-decoration: none;
}

.signup-alt-container {
  display:flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}