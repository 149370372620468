/* General body styling */
body {
  font-family: Arial, sans-serif;
  background-color: var(--body-background-color); /* Light gray background */
  color: #333 !important;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top of the body */
  height: 100vh;
  width: 100%; /* Set the width as a percentage of the screen */
}

/* Container styling */
.container {
  position: relative; /* Ensure that the container is the reference for absolutely positioned elements */
  width: 100%;
  max-width: 1200px;
  min-width:650px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dropdown-container {
  display: flex;
  align-items: center;
}

/* General button styling */
button {
  background-color: var(--button-primary-color); /* Light blue background */
  color: #ffffff; /* White text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 20px; 
  font-size: 1rem; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);  */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effect */
}
/* Button active (pressed) state */
button:active {
  background-color: var(--button-active-color); /* Even darker blue when clicked */
  transform: translateY(0); /* Button returns to original position when clicked */
}

/* Button focus state (for accessibility) */
button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0px 0px 5px var(--button-active-color); /* Add a blue outline glow for focus */
}

/* Larger button variation (for primary actions) */
button.large {
  padding: 15px 30px;
  font-size: 1.25rem;
}

/* Input and select styling */
input,
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input {
  width: calc(100% - 22px); /* Adjust width considering padding and border */
}

.label-block {
  font-weight: bold;
  margin-bottom: 5px;
}

.label-inline {
  font-weight: bold;
  margin-right: 10px;
}

/* Specific styling for the readout display */
.readout {
  background-color: white;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid var(--readout-border-color);
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  width: 100%;
  max-width: 200px;
  min-width: 150px;
  text-align: center;
}

/* Left and right controls layout */
.bottom-button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Right control buttons */
.bottom-button-group button {
  color: white;
  min-width: 150px;
}

.bottom-button-group button[disabled] {
  background-color: var(--button-disabled-color); /* Grayed out color */
  cursor: not-allowed;
}