.sensor-connect-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--device-connect-background-color);
}

.connected-device-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* margin: 20px 0; */
}

#ports {
  width: 200px;
}

#connectButton {
  width: 200px;
}
