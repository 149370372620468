/* Center spinner container at 30% of the page height */
.spinner-container {
  position: absolute;
  top: 30%; /* 30% from the top */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */
  display: flex;
  flex-direction: column; /* Stack spinner and text vertically */
  justify-content: center;
  align-items: center;
}

.spinner-container p {
  margin-top: 10px; /* Add some space between the spinner and the text */
  font-size: 14px; /* Adjust the font size as needed */
  color: #333; /* Set a color for the text */
}
