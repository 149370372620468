/* .invite-page {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: 200px;
}

.invite-page p {
  font-size: 18px;
} */

/* Center spinner container at 30% of the page height */
.invite-page {
  position: absolute;
  top: 30%; /* 30% from the top */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */
  display: flex;
  flex-direction: column; /* Stack spinner and text vertically */
  justify-content: center;
  align-items: center;
}

.invite-page p {
  margin-top: 10px; /* Add some space between the spinner and the text */
  font-size: 18px; /* Adjust the font size as needed */
  color: #333; /* Set a color for the text */
}

.invite-page .invite-page-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}