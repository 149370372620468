.settings-page-container h3 {
  text-align: center;
}

.settings-page-container {
  padding-top: 50px;
}

.terminal-container {
  display: flex;
  flex-direction: column;
}

.terminal-container label{
  margin-bottom: 10px;
}

.terminal {
  width: 100%;
  height: 400px;
  background-color: var(--settings-terminal-bg-color);
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  overflow-y: auto;
  font-family: monospace;
  white-space: pre-wrap;
}
