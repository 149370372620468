#subscription-page {
  margin-top: 70px;
}

/* Container styling */
.subscription-container {
  position: relative; /* Ensure that the container is the reference for absolutely positioned elements */
  width: 100%;
  max-width: 1200px;
  min-width: 650px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.subscription-page-section {
  padding: 20px;
  
}

.subscription-page-header-container {
  text-align: center;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Full-screen overlay to cover content when loading */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
  z-index: 9999; /* Ensure the spinner appears on top */
}

/* Center spinner container */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to center spinner */
}

.subscription-button-container {
  display: flex;
  gap: 10px; /* 10px gap between buttons */
}