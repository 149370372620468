/* Universal box-sizing rule */
* {
  box-sizing: border-box;
}

.part-editor-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Space between content and buttons */
  gap: 20px;
}

#closeMenuBtn {
  margin-left: auto; /* Push the close button to the far right */
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#closeMenuBtn:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
  margin-left: 5px;
}

.success-message {
  color: green;
  font-size: 1em;
  margin-bottom: 10px;
}

/* Centering and alignment for popup content */
#newPartFormSection h3 {
  text-align: center;
}

#newPartFormSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px !important;
  padding-top: 50px;
}

#newPartFormSection .container {
  padding: 50px; /* Ensure the inner container fits within the popup */
  text-align: left; /* Left align the inner contents */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 500px !important;
  min-width: 550px !important;
}

#newPartFormSection .container label,
#newPartFormSection .container input,
#newPartFormSection .container select {
  width: 100%;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.container label {
  font-weight: bold;
}

.container input,
#part-editor-page select {
  margin: 10px 0px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.container .button-group {
  display: flex;
  justify-content: flex-start; /* Align buttons to the left */
  gap: 10px;
}

/* partsList.css */

.action-buttons {
  text-align: right;
}

.action-buttons .edit-btn,
.action-buttons .delete-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.action-buttons .edit-btn i,
.action-buttons .delete-btn i {
  color: #007bff;
}

.action-buttons .edit-btn i:hover,
.action-buttons .delete-btn i:hover {
  color: #0056b3;
}

/* Centering the error/success messages */
#partsListSection .error-message,
#partsListSection .success-message,
#newPartFormSection .error-message,
#newPartFormSection .success-message {
  text-align: center;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.part-editor-bottom-container {
  margin: 20px 0;
}

.calculate-section .toggle-container {
  margin-top: 20px;
}

#calculateInstructions li {
  margin-top: 10px;
  margin-bottom: 10px;
}

#calculate-instruction-container {
  background-color: #f0f0f0;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 5px;
  margin-top: 10px;
  border-radius: 10px;
  
}

#calculateInstructions {
  display: "none";
}

.calculate-readout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px; /* Adjust height as needed */
  background-color: var(--item-count-background-color);
  margin: 10px 0;
  border-radius: 10px;
  width: 350px;
  text-align: center;
}

.calculate-readout-container label {
  font-size: 1em; /* Large font size */
  color: white; /* White text color */
}

/* Item count text styling */
#calculateReadoutItem {
  font-size: 3em; /* Large font size */
  color: white; /* Dark grey color */
  font-weight: bold;
}

/* #calculate-connect-button{

} */

.part-editor-form-container input{
  margin-top: 10px;
  margin-bottom:10px;
}

#part-editor-page{
  display: flex;
  flex-direction: column;
  align-items: center;
}